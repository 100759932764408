import {mapActions, mapGetters, mapMutations} from "vuex";

import {dynamicHead} from '@/mixins/dynamic-head.js'
import videoSlider from "@/modules/video/components/video-slider/index.vue";

export default {
    name: "program",
    mixins: [dynamicHead],
    components: {
        videoSlider
    },
    data() {
        return {
            params: {
                title: '',
                description: '',
                keywords: '',
                image: '',
            },
            crumbs: [
                {name: 'home', title: this.$t('mainLabel'), slug: ''},
                {name: 'program', title: this.$t('program'), slug: ''}
            ],
        }
    },
    watch: {
        '$store.getters.translation': {
            immediate: true,
            handler(newVal) {
                this.crumbs[0].title = newVal.mainLabel
                this.crumbs[1].title = newVal.program
            }
        },
    },
    created() {
        this.fetchPage('loyality-program').then(() => {
            this.setParams()
            this.setMeta()
        }).catch(error => {
            console.log(error)
        })
        this.getLoyaltyProgram()
    },
    computed: {
        ...mapGetters({
            content: 'meta/meta',
            loyaltyProgram:'loyalty/loyaltyProgram'
        })
    },

    methods: {
        ...mapActions({
            fetchPage: 'meta/GET_META',
            getLoyaltyProgram: 'loyalty/GET_LOYALTY_PROGRAM'
        }),
        ...mapMutations({}),
        setParams() {
            this.params.title = this.content.metaTitle;
            this.params.description = this.content.metaDescription;
            this.params.keywords = this.content.metaKeywords;
            this.params.image = this.content.metaImage;
        }
    }
}
